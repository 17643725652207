<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="关键字" class="keywords">
          <el-input placeholder="请输入" v-model="searchData.keywordValue" class="input-with-select" size="small">
            <el-select v-model="searchData.keywordName" slot="prepend" placeholder="请选择" size="small">
              <el-option v-for="item in searchTypeAfterSaleRemark" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-input>
        </el-form-item>

        <el-form-item label="下次回访日期">
          <el-date-picker
            style="width:280px;"
            v-model="searchData.timeSlot"
            size="small"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注时间">
          <el-date-picker
            style="width:280px;"
            v-model="searchData.remarkTimeSlot"
            size="small"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label=" " label-width="">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <div class="header-count">
      <!-- <div class="selected">
        <i class="el-icon-warning"></i>逾期待支付金额：<span>{{ memberStatic.leftOverdueAmount || 0 }}</span
        >&nbsp; 剩余待结清金额：<span>{{ memberStatic.leftTotalAmount || 0 }}</span>
      </div> -->
      <div></div>
      <el-button
        v-checkbtn="isShowBtn(AUTH_BTN.member_aftersale_remark_export)"
        type="primary"
        icon="el-icon-download"
        size="small"
        @click="exportExcel"
        >导出</el-button
      >
    </div>
    <tp-table :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="pageSize" />
    <!--  -->
    <el-dialog title="跟进记录" :visible.sync="dialogVisible" width="700px">
      <el-form ref="formRemark" :model="formData" label-width="100px">
        <el-form-item label="跟进记录" prop="content" :rules="[{ required: true, message: '该项不饿能为空', trigger: 'blur' }]">
          <el-input style="width:300px;" type="textarea" v-model="formData.content" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="下次回访时间">
          <el-date-picker
            style="width:300px;"
            v-model="formData.nextTraceDate"
            size="small"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="">
          <el-button type="" size="small" @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" size="small" @click="saveRemark">提 交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { searchTypeAfterSaleRemark } from '@/enum/dict.js'
import { addAfterSaleRemarkAPI, getAfterSaleRemarkListAPI, getAfterSaleMemberStaticAPI, DownloadAPI } from './api'
const columns = [
  {
    label: '备注时间',
    prop: 'createTime',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['createTime']}</p>
    }
  },
  {
    label: '跟进人',
    prop: 'traceUser',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['traceUser']?.['userName']}</p>
    }
  },
  {
    label: '客户姓名',
    prop: 'member',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['member']?.['memberName']}</p>
    }
  },
  {
    label: '手机号',
    prop: 'member',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['member']?.['mobile']}</p>
    }
  },
  {
    label: '备注',
    prop: 'content',
    minWidth: '200',
    customRender(h, row) {
      return <p>{row['content']}</p>
    }
  },
  {
    label: '下次回访时间',
    prop: 'nextTraceDate',
    minWidth: '150',
    fixed: 'right',
    customRender(h, row) {
      return <div>{row['nextTraceDate']}</div>
    }
  }
]
export default {
  name: 'AfterSaleAllRemark',
  mixins: [authBtnMixin],
  data() {
    return {
      dialogVisible: false,
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        keywordName: '',
        keywordValue: '',
        timeSlot: [],
        remarkTimeSlot: []
      },
      formData: {
        memberId: '',
        content: '',
        nextTraceDate: ''
      },
      memberStatic: {},
      searchTypeAfterSaleRemark,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.getList()
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  activated() {
    this.getList()
  },
  methods: {
    async getList() {
      const { timeSlot, remarkTimeSlot, keywordName, keywordValue } = this.searchData
      let params = { page: this.currentPage, pageSize: this.pageSize, memberId: this.$route.query.memberId }
      if (timeSlot && timeSlot && timeSlot.length > 0) {
        params.leftNextTraceDate = timeSlot[0]
        params.rightNextTraceDate = timeSlot[1]
      }
      if (remarkTimeSlot && remarkTimeSlot.length > 0) {
        params.leftCreateDate = remarkTimeSlot[0]
        params.rightCreateDate = remarkTimeSlot[1]
      }
      if (keywordName) params[keywordName] = keywordValue
      const res = await getAfterSaleRemarkListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
      // this.getAfterSaleMemberStatic()
    },
    // 添加备注弹窗
    handleAddRemark() {
      this.dialogVisible = true
      this.formData = {
        memberId: this.$route.query.memberId,
        content: '',
        nextTraceDate: ''
      }
    },
    saveRemark() {
      this.$refs.formRemark.validate(valid => {
        if (valid) {
          addAfterSaleRemarkAPI(this.formData).then(() => {
            this.$message.success('添加成功')
            this.dialogVisible = false
            this.getList()
          })
        }
      })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    async getAfterSaleMemberStatic() {
      this.memberStatic = await getAfterSaleMemberStaticAPI(this.$route.query.memberId)
    },
    exportExcel() {
      const { timeSlot, remarkTimeSlot, keywordName, keywordValue } = this.searchData
      let params = { page: this.currentPage, pageSize: this.pageSize, memberId: this.$route.query.memberId }
      if (timeSlot && timeSlot.length > 0) {
        params.leftNextTraceDate = timeSlot[0]
        params.rightNextTraceDate = timeSlot[1]
      }
      if (remarkTimeSlot && remarkTimeSlot.length > 0) {
        params.leftCreateDate = remarkTimeSlot[0]
        params.rightCreateDate = remarkTimeSlot[1]
      }
      if (keywordName) params[keywordName] = keywordValue
      DownloadAPI(params)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .header-search {
    .el-form {
      .el-form-item {
        margin-right: 10px;
        // .el-input {
        //   width: 250px;
        // }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 35%;
          }
        }
      }
      .keywords {
        .el-select .el-input {
          width: 100px;
        }
        .input-with-select {
          vertical-align: middle;
        }
        .input-with-select .el-input-group__prepend {
          background-color: #fff;
        }
      }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 10px;
  }
  .header-count {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .selected {
      margin-right: 20px;
      padding: 0 10px;
      flex: 1;
      background-color: #e6f7ff;
      border: 1px solid #a3dcff;
      font-size: 14px;
      align-items: center;
      display: flex;
      i {
        color: #f56c6c;
        margin-right: 10px;
        font-size: 14px;
      }
      span {
        color: #f56c6c;
        font-size: 14px;
        margin-right: 10px;
        font-weight: 600;
      }
    }
  }
  .caozuo {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .price {
    .item {
      min-width: 150px;
      display: flex;
      span {
        min-width: 70px;
        &:first-child {
          // min-width: 70px;
          flex: 1;
          text-align: right;
          // margin-right: 10px;
        }
        &:last-child {
          text-align: left;
        }
      }
    }
  }
}
</style>
