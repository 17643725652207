import { http } from '@/http/axios.js'
import axios from 'axios'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { dateFormat } from '@/utils/index.js'
import { showScreenLoading, hideScreenLoading } from '@/http/loading.js'
// 跟进记录列表
export function getAfterSaleRemarkListAPI(params) {
  return http({
    url: '/admin/afterSale/trace/page',
    method: 'get',
    params
  })
}
// 全部导出
export function DownloadAPI(data) {
  showScreenLoading()
  axios({
    method: 'post',
    url: '/api/admin/afterSale/trace/export',
    responseType: 'blob',
    data,
    headers: {
      Authorization: getToken() || ''
    }
  })
    .then(async res => {
      hideScreenLoading()
      let data = res.data
      console.log(res)
      // if (!data || data.type !== 'application/vnd.ms-excel') {
      //   let response = await new Response(data).text()
      //   response = JSON.parse(response)
      //   Message.error(response?.message || '导出失败')
      //   return
      // }
      let url = window.URL.createObjectURL(new Blob([data]))
      let a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      let filename = `售后备注${dateFormat()}.xlsx`
      a.setAttribute('download', decodeURIComponent(filename))
      document.body.appendChild(a)
      a.click() //执行下载
      window.URL.revokeObjectURL(a.href) //释放url
      document.body.removeChild(a) //释放标签
    })
    .catch(error => {
      hideScreenLoading()
    })
}
